<template>
  <div class="servicePhilosophy">
      <div class="head_bg">
         <img src="../assets/img/servicePhilosophy_bg.png" alt="">
         <div class="popover_content"></div>
         <div class="servicePhilosophy_head">
             <div class="tit-box">
                 <div>
                      <p class="title">验证</p>
                      <p class="title2">学生成长价值</p>
                 </div>
                 <div>
                    <p class="title">展现</p><p class="title2">学校育人成绩</p>
                 </div>                
             </div>
             <div class="tit-btn">
                 <div @click="centerDialogVisible = true">关注我们</div><div @click="centerDialogVisible2 = true">申请开通</div>
             </div>
         </div>
      </div>
      <div class="center-box">
            <div class="title">服务理念</div>
            <div class="shu-div"></div>
            <img src="../assets/img/servicePhilosophy_bg2.png" alt="">
      </div>
      <div class="bottom-box">
          <img src="../assets/img/servicePhilosophy_bg3.png" alt="">
          <div class="bottom-box-content">
              <div class="title">关于我们</div>
            <div class="shu-div" style="margin-bottom:80px;"></div>
            <div class="content">
                  <div class="bg-box">
                    <img src="../assets/img/servicePhilosophy_bg4.png" alt="">
                    <img class="jiantou" src="../assets/img/servicePhilosophy_bg5.png" alt="">
                  </div>
                  <div class="bottom-content">天才在线面向高校，致力于以人力资本评估技术为学校开展素质教育提供整体解决方案。通过对素质教育发展趋势的研判，运用大数据手段，为学校素质育人工作精准构建目标导向模型、科学开展育人成果评估、匹配提供覆盖第二课堂全业务的SaaS系统，让素质育人成果清晰可见，为学生成长提供专业支持。</div>
            </div>
          </div>
      </div>
   <!-- 二维码 -->
 <el-dialog
  title="请扫描二维码"
  :visible.sync="centerDialogVisible"
  width="300px"
  center>
  <img  width="250px" src="../assets/img/TJL_QRCode.jpg" alt="">
</el-dialog>
   <!-- 联系方式 -->
 <el-dialog
  title="联系方式"
  :visible.sync="centerDialogVisible2"
  width="300px"
  center>
  <div>
         <p>联系人:张老师</p>
        <span>联系电话:010-82825072 <br/>地址:北京 中关村软件园 2A2232</span>
        <p>请打开微信扫描下方二维码申请开通</p>
        <img  width="250px" src="../assets/img/wxcode.jpg" alt="">
  </div>
</el-dialog>
  </div>
</template>

<script>
export default {
  name: 'servicePhilosophy',
  data() {
      return {
          centerDialogVisible: false,
          centerDialogVisible2: false,
      };
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
p{
    margin: 0;
    padding: 0;
}
.head_bg{
    position: relative;
        display: flex;
    justify-content: center;
    img{
        display: block;
        width: 100%;
    }
    .popover_content{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(77,120,147,.5);
    }
}
.servicePhilosophy_head{
    margin: 0 auto;
    width: 470px;
    position: absolute;
    top: 115px;
    z-index: 5;
    text-align: center;
    .tit-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title{
        font-size: 46px;
        color: #FFFFFF;
        font-weight: bold;
        padding-bottom: 20px;
        letter-spacing:3px;
        position: relative;
        text-align: left;
    }
    .title:before{
            display: block;
            content: '';
            width: 70px;
            height: 4px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
    }
    .title2{
        font-size: 30px;
        color: #fff;
        margin-top: 20px;
    }
    .tit-btn{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 130px;
        div{
            width: 182px;
            height: 54px;
            background: #01C7D8;
            text-align: center;
            line-height: 54px;
            font-size: 22px;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
            border-radius: 5px;
            border:1px solid #fff;
        }
    }
}
.center-box{
    background: #fff;
    overflow: hidden;
    .title{
        font-size: 36px;
        color: #20A8D9;
        font-weight: bold;
        margin-top: 120px;
    }
    img{
        margin-bottom: 150px;
        width: 882px;
    }
}
.shu-div{
    background: #20A8D9;
    width: 4px;
    height: 30px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 120px;
}
.bottom-box{
    position: relative;
    img{
        width: 100%;
    }
    .bottom-box-content{
       position: absolute;
       top: 0;
       width: 1200px;
       left: 50%;
       margin-left: -600px;
      .title{
        font-size: 36px;
        font-weight: bold;
        color: #20A8D9;
        margin-top: 64px;
      }
    }
    .content{
        width: 100%;
        height: 410px;
        background: url('../assets/img/servicePhilosophy_bg9.png') no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 513px;
        }
        .jiantou{
            width: 50px;
            position: absolute;
            top: 50%;
            margin-top: -25px;
            right: -25px;
        }
        .bg-box{
            position: relative;
            img{
                display: block;
            }
        }
        .bottom-content{
            font-size: 18px;
            color: #333333;
            padding:100px 70px;
            text-align: left;
            line-height: 30px;
        }
    }
}
</style>

